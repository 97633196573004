import React, { createContext, useState } from 'react';

export const themes = {
    dark: {
        background: '#424242',
        color: '#a99898',
    },
    light: {
        background: 'white',
        color: '#5d5050',
    },
};

const defaultThemeKey = 'light';
const defaultThemeContextValue = {
    data: themes[defaultThemeKey],
    key: defaultThemeKey,
};

const ThemeContext = createContext([
    defaultThemeKey,
    () => defaultThemeContextValue,
]);

// eslint-disable-next-line react/prop-types
const ThemeProvider = ({ children }) => {
    const [state, setState] = useState(defaultThemeContextValue);

    const setStateWrapper = v => setState({ data: themes[v], key: v });

    return (
        <ThemeContext.Provider value={[state, setStateWrapper]}>
            {children}
        </ThemeContext.Provider>
    );
};

export { ThemeProvider, ThemeContext };
