import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
// import classnames from 'classnames';

import { rhythm, scale } from '../utils/typography';
// import Search from './search';
import Toggle from './toggle';

const Header = ({ isRoot, headerUrl, title, themeKey, onChangeTheme }) => {
    let homepageBtn = null;
    if (isRoot) {
        homepageBtn = (
            <h1
                style={{
                    ...scale(1),
                    marginBottom: rhythm(1.5),
                    marginTop: 0,
                }}
            >
                <Link to={headerUrl}>{title}</Link>
            </h1>
        );
    }
    homepageBtn = (
        <h3 style={{ marginTop: 0 }} className="row">
            <Link className="row" to={headerUrl}>
                Trang Chủ
            </Link>
        </h3>
    );

    return (
        <div className="row">
            {homepageBtn}
            {/* <Search /> */}
            <Toggle
                checked={themeKey === 'dark'}
                onChange={e => onChangeTheme(e.target.checked)}
            />
        </div>
    );
};

Header.propTypes = {
    isRoot: PropTypes.bool,
    title: PropTypes.string.isRequired,
    headerUrl: PropTypes.string.isRequired,
    themeKey: PropTypes.string,
    onChangeTheme: PropTypes.func.isRequired,
};

Header.defaultProps = {
    isRoot: false,
};

export default Header;
