import React, { useContext, useEffect } from 'react';
import ReactGA from 'react-ga';

import { rhythm } from '../utils/typography';
import { ThemeContext } from '../providers/ThemeProvider';
import Header from './header';
import * as LocalStorage from '../utils/storage';

const Layout = ({ location, title, children, keyId = '' }) => {
    const [theme, setCurrentTheme] = useContext(ThemeContext);

    const getLocalTheme = () => {
        const data = LocalStorage.getTheme();
        setCurrentTheme(data);
    };

    const setTheme = checked => {
        const updateTheme = value => {
            setCurrentTheme(value);
            LocalStorage.setTheme(value);
        };
        if (checked) {
            updateTheme('dark');
        } else {
            updateTheme('light');
        }
    };

    useEffect(() => {
        const currentPath =
            typeof window !== 'undefined' ? window.location.pathname : keyId;
        const startTime = new Date().getTime();

        ReactGA.pageview(currentPath);
        getLocalTheme();

        return () => {
            const endTime = new Date().getTime();
            const duration = (endTime - startTime) / 1000;
            ReactGA.timing({
                category: 'Page-Live',
                variable: 'live',
                value: duration, // in milliseconds
                label: currentPath,
            });
        };
    }, []);

    const rootPath = `${__PATH_PREFIX__}/`;
    const isRoot = location.pathname === rootPath;
    const headerUrl = `/${
        typeof window !== 'undefined' ? window.location.search : ''
    }`;

    const themeData = theme.data || {};

    return (
        <div
            style={{
                backgroundColor: themeData.background,
                color: themeData.color,
                minHeight:
                    typeof window !== 'undefined' ? window.innerHeight : 1080,
            }}
        >
            <div
                style={{
                    position: 'relative',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    maxWidth: rhythm(24),
                    padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
                }}
            >
                <header>
                    <Header
                        themeKey={theme.key}
                        headerUrl={headerUrl}
                        title={title}
                        isRoot={isRoot}
                        onChangeTheme={setTheme}
                    />
                </header>
                <main>{children}</main>
                <footer>
                    © 2019, Built by <a href="http://daonamtien.com"> me</a>
                </footer>
            </div>
        </div>
    );
};

export default Layout;
