const KEY_STORAGE = {
    Theme: 'theme',
};

const getTheme = () => {
    return localStorage.getItem(KEY_STORAGE.Theme);
};

const setTheme = theme => {
    localStorage.setItem(KEY_STORAGE.Theme, theme);
};

export { getTheme, setTheme };
