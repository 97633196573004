/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ description, lang, meta, keywords, title, image }) => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        image
                    }
                }
            }
        `
    );
    const metaDescription = description || site.siteMetadata.description;
    const imageDescription = image || site.siteMetadata.image;

    const newMeta = [
        {
            name: 'description',
            content: metaDescription,
        },
        {
            property: 'og:title',
            content: title,
        },
        {
            property: 'og:description',
            content: metaDescription,
        },
        {
            property: 'og:type',
            content: 'website',
        },
        {
            property: 'og:image',
            content: imageDescription,
        },
        {
            property: 'og:image:width',
            content: 200,
        },
        {
            property: 'og:image:height',
            content: 200,
        },
        {
            name: 'twitter:card',
            content: 'summary',
        },
        {
            name: 'twitter:creator',
            content: site.siteMetadata.author,
        },
        {
            name: 'twitter:title',
            content: title,
        },
        {
            name: 'twitter:description',
            content: metaDescription,
        },
        {
            name: 'twitter:image',
            content: imageDescription,
        },
    ]
        .concat(
            keywords.length > 0
                ? {
                      name: 'keywords',
                      content: keywords.join(', '),
                  }
                : []
        )
        .concat(meta);

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={newMeta}
        />
    );
};

SEO.defaultProps = {
    lang: 'en',
    meta: [],
    keywords: [],
    description: '',
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    keywords: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string.isRequired,
};

export default SEO;
